<template>
	<v-app>
		<v-card>
			<template v-if="cameraImei">
				<v-card-title>カメラの編集</v-card-title>
			</template>
			<template v-else>
				<v-card-title>新規カメラ</v-card-title>
			</template>
			<v-card-text>
				<v-label> IMEI </v-label>
				<v-text-field
					:rules="[rules.required]"
					v-model="camera.imei"
					outlined
					disabled
				></v-text-field>

				<v-label>名称</v-label>
				<v-text-field :rules="[rules.required, rules.max]" counter="20" outlined v-model="camera.name"> </v-text-field>

				<v-label>電話番号</v-label>
				<v-text-field :rules="[rules.max2]" counter="12" outlined v-model="camera.phoneNumber">
				</v-text-field>

				<v-label>地点</v-label>
				<v-select
					v-model="camera.placeId"
					:items="places"
					outlined
				></v-select>
			</v-card-text>
			<template v-if="cameraImei">
				<v-card-actions>
					<v-btn depressed @click="save" class="save">保存</v-btn>
				</v-card-actions>
			</template>
			<template v-else>
				<v-card-actions>
					<v-btn depressed @click="save" class="save"
						>カメラを追加</v-btn
					>
				</v-card-actions>
			</template>
		</v-card>
		<v-footer>
			©2021 <a href="https://www.vegetalia.co.jp/">vegetalia</a>, inc.
		</v-footer>
	</v-app>
</template>

<script>
import axios from "axios";

export default {
	components: {},
	data: () => ({
		cameraImei: "",
		camera: {},
		places: [],
		rules: {
			required: v => !!v || '必須項目',
			max: v => v.length <= 20 || "20文字以内",
			max2: v => v.length <= 12 || "12文字以内",
		},
	}),
	created() {
		axios.defaults.headers.common["Authorization"] =
			"Bearer " + this.$store.state.auth.data.token;
		this.initialize();
	},
	mounted() {
		this.cameraImei = this.$route.params.id;
	},
	methods: {
		initialize() {
			this.showLoading();
			axios
				.get(
					"https://" +
						this.config.defaultOriginString +
						"/api/admin-update-camera?id=" +
						this.$route.params.id
				)
				.then((res) => {
					this.hideLoading();
					// 成功した場合
					if (res && res.data && res.data.status == "success") {
						this.camera = res.data.camera;
						this.places = res.data.places;
						this.places.push({ value: "", text: "地点なし" });
						console.log("Success: " + JSON.stringify(res.data));
					} else {
						console.log("Fail: " + JSON.stringify(res));
					}
				})
				.catch((error) => {
					this.hideLoading();
					console.log(error);
				});
		},
		save() {
			if (!this.camera.name || this.camera.name.length > 20) {
				alert("カメラ名称は20文字以内で指定してください");
				return;
			}
			if (this.camera.phoneNumber && this.camera.phoneNumber.length > 12) {
				alert("電話番号は12文字以内で指定してください");
				return;
			}
			this.showLoading();
			const params = {
				imei: this.cameraImei,
				name: this.camera.name,
			};
			if (this.camera.placeId) params.placeId = this.camera.placeId;
			if (this.camera.phoneNumber)
				params.phoneNumber = this.camera.phoneNumber;
			axios
				.post(
					"https://" +
						this.config.defaultOriginString +
						"/api/admin-update-camera",
					params
				)
				.then((res) => {
					this.hideLoading();
					// 成功した場合
					if (res && res.data && res.data.status == "success") {
						console.log("Success: " + JSON.stringify(res.data));
						this.$router
							.push({ name: "AdminCamera" })
							.catch(() => {});
					} else {
						console.log("Fail: " + JSON.stringify(res));
						alert("名称は必須項目です");
					}
				})
				.catch((error) => {
					this.hideLoading();
					console.log(error);
					alert("名称は必須項目です");
				});
		},
		showLoading: function () {
			this.$emit("loadShowHide", true);
		},
		hideLoading: function () {
			this.$emit("loadShowHide", false);
		},
	},
};
</script>


